// extracted by mini-css-extract-plugin
export var column = "LocationShowContentBrochure__column__azZ0p";
export var downloadContainer = "LocationShowContentBrochure__downloadContainer__oRM8P";
export var image = "LocationShowContentBrochure__image__FCKac";
export var imageContainer = "LocationShowContentBrochure__imageContainer__rV2A5";
export var locationBrochure = "LocationShowContentBrochure__locationBrochure__QhI8y";
export var paginationContainer = "LocationShowContentBrochure__paginationContainer__IJNuM";
export var row = "LocationShowContentBrochure__row__jLMge";
export var subtitle = "LocationShowContentBrochure__subtitle__q5peU";
export var title = "LocationShowContentBrochure__title__mit4Q";
export var titleContainer = "LocationShowContentBrochure__titleContainer__HcTK7";