// extracted by mini-css-extract-plugin
export var column = "LocationShowContact__column__V7cZ7";
export var envelopeImage = "LocationShowContact__envelopeImage__Bbzid";
export var locationContactContainer = "LocationShowContact__locationContactContainer__WQGxJ";
export var locationContactEnvelope = "LocationShowContact__locationContactEnvelope__Y61pn";
export var locationContactHeader = "LocationShowContact__locationContactHeader__qlUQ0";
export var locationContactJobTitle = "LocationShowContact__locationContactJobTitle__nKpf7";
export var locationContactMessage = "LocationShowContact__locationContactMessage__VfItT";
export var locationContactMessageSub = "LocationShowContact__locationContactMessageSub__A_6Lk";
export var locationContactName = "LocationShowContact__locationContactName__CxgoS";
export var locationContactPerson = "LocationShowContact__locationContactPerson__ZAWP3";
export var locationContactPersonImage = "LocationShowContact__locationContactPersonImage__GMHxa";
export var locationContactSubmitted = "LocationShowContact__locationContactSubmitted__ZeSaF";
export var locationContactSubtitle = "LocationShowContact__locationContactSubtitle__oC_lz";
export var locationContactSummary = "LocationShowContact__locationContactSummary__rWhLi";
export var locationContactTitle = "LocationShowContact__locationContactTitle__SDXsi";
export var row = "LocationShowContact__row__n_b7c";
export var salesManagerImage = "LocationShowContact__salesManagerImage__EUWsy";