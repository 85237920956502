// extracted by mini-css-extract-plugin
export var certification = "LocationShowContentCertifications__certification__yF4qV";
export var certificationLabelContainer = "LocationShowContentCertifications__certificationLabelContainer__wFCGN";
export var certificationTitle = "LocationShowContentCertifications__certificationTitle__Hh5oR";
export var certificationTooltip = "LocationShowContentCertifications__certificationTooltip__j3J_u";
export var certifications = "LocationShowContentCertifications__certifications__DWfn7";
export var certificationsRow = "LocationShowContentCertifications__certificationsRow__CC_he";
export var certificationsTitle = "LocationShowContentCertifications__certificationsTitle__bp3q4";
export var column = "LocationShowContentCertifications__column__kEUoQ";
export var noCertifications = "LocationShowContentCertifications__noCertifications__SRG6O";
export var row = "LocationShowContentCertifications__row__Mri0T";