// extracted by mini-css-extract-plugin
export var column = "LocationShowRelatedLocation__column__OMi6Y";
export var relatedLocationChevron = "LocationShowRelatedLocation__relatedLocationChevron__TIy77";
export var relatedLocationContainer = "LocationShowRelatedLocation__relatedLocationContainer__WiB6U";
export var relatedLocationImage = "LocationShowRelatedLocation__relatedLocationImage__YeLqA";
export var relatedLocationLeft = "LocationShowRelatedLocation__relatedLocationLeft__QV9tX";
export var relatedLocationLink = "LocationShowRelatedLocation__relatedLocationLink__P2d5j";
export var relatedLocationRight = "LocationShowRelatedLocation__relatedLocationRight__A4vEy";
export var relatedLocationSummary = "LocationShowRelatedLocation__relatedLocationSummary__eWk7g";
export var relatedLocationSummaryAddress = "LocationShowRelatedLocation__relatedLocationSummaryAddress__jWDlE";
export var relatedLocationSummaryProvider = "LocationShowRelatedLocation__relatedLocationSummaryProvider__NlCsP";
export var row = "LocationShowRelatedLocation__row__OLu7N";