// extracted by mini-css-extract-plugin
export var bottomContainer = "LocationShow__bottomContainer__wQB3Z";
export var breadcrumbLink = "LocationShow__breadcrumbLink__vatXJ";
export var breadcrumbs = "LocationShow__breadcrumbs__v2eDD";
export var column = "LocationShow__column__naNiq";
export var container = "LocationShow__container__cMtug";
export var leftContainer = "LocationShow__leftContainer__d3aj_";
export var locationDetails = "LocationShow__locationDetails__IVedn";
export var mainContent = "LocationShow__mainContent__w1JgI";
export var rightContainer = "LocationShow__rightContainer__xjUpa";
export var row = "LocationShow__row__plEUZ";
export var sidebar = "LocationShow__sidebar__Pqjuu";
export var topContainer = "LocationShow__topContainer__Fjpnw";